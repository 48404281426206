@import '~bulma/bulma.sass';
html,body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.hero.is-info {
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ), url('bkg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media screen and (max-width: 1023px) {
  .hero.is-info .navbar-menu {
    background-color: transparent;
    box-shadow: none;
  }
}
.hero .subtitle {
  padding: 3rem 0;
  line-height: 1.5;
}
.content a {
  color:#73a0e7 !important;
}
.label {
  color: white;
}